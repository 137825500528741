<template>
	<div class="flex_wrap pt-50 pb-50">
		<div class="version">
			<span>ver {{ version }}</span>
		</div>
		<div
			class="btn_area bg_w" style="position: fixed; left: 0; bottom: 80px; width: 100%"
		>
			<div style="text-align: center; width: 100%; padding: 0 10px">
				<button
					v-if="item_version.update_code != 'AP00300001'"
					class="btn_l btn_fill_blue" style="width: 100%"
					@click="doUpdate"
				>{{  $language.common.update }} </button>
				<p
					style="font-size: 14px;"
				>{{ item_version.update_message }}</p>
			</div>
		</div>
	</div>
	<!-- //내용 영역 -->
	<!-- 하단 고정 버튼 -->
</template>

<script>
	export default {
		name: 'mafia117'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.version_info
					, title: this.$language.common.version
					, type: 'mypage'
				}
				, item_version: {
					update_code: ''
					, update_message: ''
					, update_url: ''
				}
			}
		}
		, computed: {
			is_new: function(){
				let t = false
				if(this.update_code == ''){
					t = true
				}

				return t
			}
			, version: function(){
				let t = this.$encodeStorage.getSessionVersion()
				return t
			}
		}
		,methods: {
			getData: async function(){
				try {

					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_app_version_info
						, data: {
							member_number: this.user.member_number
							, app_version: this.version
						}
						, type: true

					})
					if (result.success) {
						this.item_version = result.data

						for(let [k, v] of Object.entries(this.item_version)){
							//alert(k + ':' + v)
							console.log(k, v)
						}
					} else {
						throw result.message
					}
				}catch (e){
					console.log(e)
					// this.$bus.$emit('notify',  { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, doUpdate: function(){
				try {
					this.$common.inAppWebviewCommunity('android', 'updateApp')
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_update})
				}
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>